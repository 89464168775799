import styled from '@emotion/styled';
import {css} from '@emotion/react';

export function DropMenu({display}: {display: string}) {
  return (
    <Menu display={display}>
      <Item href="#productService">제공 서비스</Item>
      <Item href="#projectIntroduction">프로젝트 소개</Item>
      <Item href="#history">연혁</Item>
      <Item href="#employee">직원</Item>
    </Menu>
  );
}

const dynamicDisplay = ({display}: {display: string}) =>
  css`
    display: ${display};
  `;

const Menu = styled.div`
  position: absolute;
  top: 70px;
  left: calc(100% - 150px);
  background: white;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  width: 150px;
  padding: 10px;
  flex-flow: column nowrap;
  ${dynamicDisplay}
`;

const Item = styled.a`
  cursor: pointer;
  width: 100%;
  font-size: 15px;
  border-left: 3px #888888 solid;
  padding: 0 0 0 10px;
  margin: 10px 0;
  &:visited {
    color: black;
  }
  &:hover {
    font-weight: 500;
    border-color: #50bd5f;
    color: #50bd5f;
  }
`;
