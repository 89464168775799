import styled from '@emotion/styled';

export function Logo() {
  return (
    <a href="#main">
      <Image src="/image/logo.png" alt="하이퍼서트 로고" />
    </a>
  );
}

const Image = styled.img`
  width: auto;
  height: 45px;
  object-fit: contain;
  cursor: pointer;
`;
