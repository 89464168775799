import styled from '@emotion/styled';
import mq from '../style/mq';

export function Title() {
  return (
    <>
      <Text>History</Text>
      <Des data-aos="zoom-in-up">HYPERCERTS의 성과</Des>
    </>
  );
}

const Text = styled.div`
  font-size: 40px;
  font-weight: 300;
  margin-top: 80px;
  margin-bottom: 20px;
  ${mq()({
    marginLeft: ['20px', '80px'],
  })}
`;

const Des = styled.div`
  font-size: 20px;
  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 20px;
  color: #5b5b5b;
  ${mq()({
    marginLeft: ['20px', '80px'],
  })}
`;
