import styled from '@emotion/styled';
import {Cards} from './Cards';
import {Title} from './Title';

export function ProjectIntroduction() {
  return (
    <Container id="projectIntroduction">
      <Title />
      <Cards />
    </Container>
  );
}

const Container = styled.section`
  width: 100%;
  padding-top: 100px;
`;
