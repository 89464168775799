import styled from '@emotion/styled';
import {css} from '@emotion/react';

export function Cards() {
  const data = [
    {
      icon: '/image/icon_BlockchainSolution.png',
      title: 'Blockchain Solution',
      des: '미술 작품 진품 인증, 소유권 인증 및 이력 관리 솔루션 Qart, 명품 진품 소유권 인증 솔루션 unBeil, 자전거 소유권 인증 솔루션 Biown 제공',
    },
    {
      icon: '/image/icon_ExpertService.png',
      title: 'Expert Service',
      des: '블록체인 전문기업으로 블록 체인 컨설팅, 블록체인 교육 블록체인 시스템 개발 및 구축 서비스 제공',
    },
    {
      icon: '/image/icon_RD.png',
      title: 'R & D',
      des: '개인정보의 잊혀질 권리등을 위한 수정, 삭제 가능한 블록체인 플랫폼 연구, 블록체인 기반 디지털 저작권 시스템 연구 미술품 및 귀금속 등의 디지털 자산화 시스템 연구 수행',
    },
  ];
  return (
    <Container>
      {data.map((item, index) => (
        <Card key={index} index={index} data-aos="zoom-in">
          <Wrap>
            <Icon src={item.icon} alt="icon" />
            <Title>{item.title}</Title>
          </Wrap>
          <Wrap>
            <Hr />
            <Des>{item.des}</Des>
          </Wrap>
        </Card>
      ))}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  bottom: 280px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

const Wrap = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const dynamicMargin = ({index}: {index: number}) =>
  css`
    margin-top: ${index * 50}px;
    @media (max-width: 989px) {
      margin-top: 0;
    }
  `;

const Card = styled.div`
  width: 100%;
  max-width: 300px;
  height: 370px;
  background: white;
  margin: 15px;
  padding: 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  ${dynamicMargin}
`;

const Icon = styled.img`
  width: 50px;
  height: auto;
  object-fit: contain;
  margin-bottom: 10px;
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: 500;
  margin-top: 5px;
`;

const Hr = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  width: 60px;
  border-top: 2px solid black;
  margin: 12px 0;
  padding: 0;
`;

const Des = styled.span`
  font-size: 15px;
  font-weight: 300;
  line-height: 25px;
  word-break: keep-all;
`;
