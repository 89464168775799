import styled from '@emotion/styled';
import mq from '../style/mq';

export function Title() {
  return (
    <>
      <Text>Employee</Text>
    </>
  );
}

const Text = styled.div`
  font-size: 40px;
  font-weight: 300;
  padding-top: 80px;
  margin-bottom: 20px;
  ${mq()({
    marginLeft: ['20px', '80px'],
  })}
`;
