import styled from '@emotion/styled';
import {css} from '@emotion/react';

export function BackgroundBox({url}: {url: string}) {
  return <Box url={url} />;
}

const dynamicBg = ({url}: {url: string}) =>
  css`
    background-image: url(${url});
  `;

const Box = styled.div`
  flex: 1 1 0;
  height: 250px;
  background-size: cover;
  ${dynamicBg}
`;
