import styled from '@emotion/styled';
import {Logo} from './Logo';
import {Menu} from './Menu';
import {Burger} from './Burger';
import {useEffect, useState} from 'react';
import {css} from '@emotion/react';
import {DropMenu} from './DropMenu';

export function Header() {
  const [bgColor, setBgColor] = useState<string>('none');
  const handleHeaderColor = () => {
    if (window.scrollY > 100) {
      setBgColor('white');
    } else {
      setBgColor('none');
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleHeaderColor);
  }, []);
  const [display, setDisplay] = useState<string>('none');
  return (
    <Container color={bgColor}>
      <Logo />
      <Menu />
      <Burger display={display} setDisplay={setDisplay} />
      <DropMenu display={display} />
    </Container>
  );
}

const dynamicBg = ({color}: {color: string}) =>
  css`
    background: ${color};
  `;
const Container = styled.header`
  position: fixed;
  width: 100%;
  height: 70px;
  top: 0;
  left: 0;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.7);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background: none;
  z-index: 999;
  ${dynamicBg}
`;
