import styled from '@emotion/styled';
import mq from '../style/mq';

export function ProjectBox() {
  return (
    <Box
      data-aos="fade-right"
      onClick={() => (window.location.href = '#projectIntroduction')}
    >
      <Hr />
      <Title>
        Project
        <br /> introduction
      </Title>
      <Des>하이퍼서트 프로젝트 바로가기</Des>
      <DownButton src="/image/button.png" alt="프로젝트 바로가기" />
    </Box>
  );
}

const Box = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 35px;
  width: 350px;
  height: 300px;
  background-color: #50bd5f;
  position: relative;
  bottom: 200px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  z-index: 998;
  ${mq()({
    width: ['280px', '350px'],
  })}
`;

const Hr = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  width: 80px;
  border-top: 2px solid #ffffff;
  margin: 1em 0;
  padding: 0;
`;

const Title = styled.span`
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 30px;
  ${mq()({
    fontSize: ['32px', '38px'],
  })}
`;

const Des = styled.span`
  font-weight: 300;
  color: #ffffff;
  ${mq()({
    fontSize: ['12px', '14px'],
  })}
`;

const DownButton = styled.img`
  width: 45px;
  height: auto;
  object-fit: contain;
  margin-left: auto;
`;
