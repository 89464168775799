import styled from '@emotion/styled';
import mq from '../style/mq';

export function Burger({
  display,
  setDisplay,
}: {
  display: string;
  setDisplay: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <Image
      src="/image/menu.png"
      alt="메뉴 열기"
      onClick={() =>
        display === 'flex' ? setDisplay('none') : setDisplay('flex')
      }
    />
  );
}

const Image = styled.img`
  width: auto;
  height: 30px;
  object-fit: cover;
  cursor: pointer;
  ${mq()({
    display: ['block', 'none'],
  })}
`;
