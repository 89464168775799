import styled from '@emotion/styled';
import mq from '../style/mq';

export function Timeline() {
  return (
    <Wrap>
      <Line data-aos="fade-left">
        <Item />
        <Item>
          <Text>
            <Title>2018.10</Title>
            <Des>아시아 경제 TV 블록배틀 본선 진출</Des>
          </Text>
        </Item>
        <Item />
        <Item>
          <Text>
            <Title>2020.10</Title>
            <Des>UnBeil 앱 출시</Des>
          </Text>
        </Item>
        <Item />
      </Line>
      <Line data-aos="fade-right">
        <Item>
          <CircleLineWhite />
          <Circle />
          <CircleLine />
        </Item>
        <Item>
          <CircleLine />
          <Circle />
          <CircleLine />
        </Item>
        <Item>
          <CircleLine />
          <Circle />
          <CircleLine />
        </Item>
        <Item>
          <CircleLine />
          <Circle />
          <CircleLine />
        </Item>
        <Item>
          <CircleLine />
          <Circle />
          <CircleLineWhite />
        </Item>
      </Line>
      <Line data-aos="fade-left">
        <Item>
          <Item>
            <Text>
              <Title>2018.01</Title>
              <Des>UnBeil 앱 출시</Des>
            </Text>
          </Item>
        </Item>
        <Item />
        <Item>
          <Text>
            <Title>2019.07</Title>
            <Des>AutoInSleep 발표</Des>
          </Text>
        </Item>
        <Item />
        <Item>
          <Text>
            <Title>2021.01</Title>
            <Des>Qart 출시</Des>
          </Text>
        </Item>
      </Line>
    </Wrap>
  );
}

const Wrap = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 50px;
  ${mq()({
    padding: ['0 5px', '0 80px'],
  })}
`;

const Line = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  ${mq()({
    height: ['60px', '50px'],
  })}
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Text = styled.div`
  display: flex;
  height: 100%;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
`;

const Title = styled.span`
  font-weight: 400;
  font-size: 16px;
  color: #2d2d2d;
  text-align: center;
`;

const Des = styled.span`
  font-weight: 300;
  color: #5b5b5b;
  word-break: keep-all;
  text-align: center;
  ${mq()({
    fontSize: ['10px', '12px'],
  })}
`;

const Circle = styled.div`
  width: 10px;
  height: 10px;
  background: #50bd5f;
  border-radius: 50%;
`;

const CircleLine = styled.div`
  flex: 1;
  height: 2px;
  background: #50bd5f;
`;

const CircleLineWhite = styled.div`
  flex: 1;
  height: 2px;
  background: #ffffff;
`;
