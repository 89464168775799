import styled from '@emotion/styled';
import {BackgroundBox} from './BackgroundBox';
import {ProjectBox} from './ProjectBox';
import {LineBox1} from './LineBox1';
import {LineBox2} from './LineBox2';

export function Main() {
  return (
    <Container id="main">
      <BackgroundBox />
      <ProjectBox />
      <LineBox1 />
      <LineBox2 />
    </Container>
  );
}

const Container = styled.section`
  width: 100%;
  height: 900px;
`;
