import styled from '@emotion/styled';
import {BackgroundBox} from './BackgroundBox';
import {TitleBox} from './TitleBox';
import {Cards} from './Cards';
import {css} from '@emotion/react';

export function ProductService() {
  return (
    <Container id="productService">
      <BackgroundBox />
      <TitleBox />
      <Cards />
    </Container>
  );
}

const dynamicHeight = () =>
  css`
    @media (max-width: 989px) {
      max-height: 1000px;
    }
    @media (max-width: 659px) {
      max-height: 1400px;
    }
  `;

const Container = styled.section`
  width: 100%;
  max-height: 900px;
  ${dynamicHeight}
`;
