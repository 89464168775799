import styled from '@emotion/styled';

export function BackgroundBox() {
  return <Box />;
}

const Box = styled.div`
  width: 100%;
  max-width: 500px;
  height: 300px;
  background-size: cover;
  background-image: url('/image/image_ProductService.png');
`;
