import styled from '@emotion/styled';
import mq from '../style/mq';

export function LineBox2() {
  return <Box />;
}

const Box = styled.div`
  position: absolute;
  top: 0;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.7);
  width: 100%;
  z-index: 2;
  ${mq()({
    height: ['350px', '550px'],
  })}
`;
