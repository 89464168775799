import styled from '@emotion/styled';

export function Footer() {
  return (
    <Container>
      <Title>hypercerts</Title>
      <Des>Location.&nbsp; 대전광역시 서구 청사로 136, R1244호</Des>
      <Des>Email.&nbsp; seungwookj@gmail.com</Des>
      <Des>Tel.&nbsp; 82+ 041 730 5597</Des>
      <IconWrap>
        <Icon href="https://www.facebook.com/groups/835065267075390">
          <img src="/image/1-3.png" alt="facebook" />
        </Icon>
        <Icon href="https://groups.google.com/a/hypercerts.com/g/group">
          <img src="/image/1-2.png" alt="google" />
        </Icon>
        <Icon href="https://www.linkedin.com/in/zero4sum/">
          <img src="/image/1-4.png" alt="linkedin" />
        </Icon>
        <Icon href="https://twitter.com/47Tvxi20xcbCT1q">
          <img src="/image/1-1.png" alt="twitter" />
        </Icon>
      </IconWrap>
    </Container>
  );
}

const Container = styled.header`
  margin-top: 50px;
  width: 100%;
  height: 250px;
  background: #58585a;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.span`
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 10px;
`;

const Des = styled.span`
  font-weight: 300;
  font-size: 14px;
  color: #f1f1f1;
`;

const IconWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;
const Icon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 5px;
  & > img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;
