import styled from '@emotion/styled';
import mq from '../style/mq';

export function TitleBox({
  name,
  position,
  des,
}: {
  name: string;
  position: string;
  des: string;
}) {
  return (
    <>
      <TextBox data-aos="fade-left">
        <Title>{name}</Title>
        <Des>{position}</Des>
        <Hr />
        <Des>{des}</Des>
      </TextBox>
    </>
  );
}

const TextBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 2 1 0;
  margin-top: auto;
  padding: 20px;
  background-color: #f8f8f8;
  ${mq()({
    paddingLeft: ['20px', '80px'],
  })}
`;

const Title = styled.span`
  font-size: 18px;
  font-weight: 400;
  margin-top: 5px;
`;

const Hr = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  width: 60px;
  border-top: 2px solid black;
  margin: 40px 0 12px;
  padding: 0;
`;

const Des = styled.span`
  font-weight: 300;
  line-height: 25px;
  word-break: keep-all;
  ${mq()({
    fontSize: ['12px', '15px'],
  })}
`;
