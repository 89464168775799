import styled from '@emotion/styled';

export function TitleBox() {
  return <Box data-aos="fade-down">Product service</Box>;
}

const Box = styled.div`
  position: relative;
  padding: 25px;
  bottom: 200px;
  width: 100%;
  height: 200px;
  background: #f1f1f1;
  font-weight: 400;
  font-size: 30px;
  color: #2d2d2d;
`;
