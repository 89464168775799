import facepaint from 'facepaint';

type DeviceSize = 's';

type DeviceWidth = {
  [key in DeviceSize]: number;
};

const deviceWidth: DeviceWidth = {
  s: 700,
};

const mq = (breakpoints = deviceWidth) => {
  const mediaQueries: string[] = Object.values(breakpoints)
    .sort((curr: number, next: number) => curr - next)
    .map((breakpoint: number) => `@media(min-width: ${breakpoint}px)`);

  return facepaint(mediaQueries, {overlap: true});
};

export default mq;
