import React, {useEffect} from 'react';
import {Header} from './Header';
import {Main} from './Main';
import 'aos/dist/aos.css';
import AOS from 'aos';
import {ProductService} from './ProductService';
import {ProjectIntroduction} from './ProjectIntroduction';
import {History} from './History';
import {Employee} from './Employee';
import {Footer} from './Footer';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 100,
    });
  });
  return (
    <>
      <Header />
      <Main />
      <ProductService />
      <ProjectIntroduction />
      <History />
      <Employee />
      <Footer />
    </>
  );
}

export default App;
