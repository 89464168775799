import styled from '@emotion/styled';

export function Wrap({children}: {children: JSX.Element}) {
  return (
    <>
      <Container>{children}</Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 20px 0;
`;
