import styled from '@emotion/styled';
import mq from '../style/mq';

export function LineBox1() {
  return <Box />;
}

const Box = styled.div`
  position: absolute;
  top: 0;
  border-right: 0.5px solid rgba(255, 255, 255, 0.7);
  height: 600px;
  z-index: 1;
  ${mq()({
    width: ['110px', '400px'],
  })}
`;
