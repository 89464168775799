import styled from '@emotion/styled';
import {Title} from './Title';
import {BackgroundBox} from './BackgroundBox';
import {TitleBox} from './TitleBox';
import {Wrap} from './Wrap';

const data = [
  {
    url: '/image/Employee2.png',
    name: 'Kang Seongun',
    position: 'Developer, PM',
    des: '메인 개발자이자 프로젝트 매니저.',
  },
  {
    url: '/image/Employee1.png',
    name: 'Seong Yungyeong',
    position: 'Founder, CEO',
    des: '미국 메릴랜드 주립대에서 경영학을 전공하였고, 블록체인의 가능성에 주목하여 한국에서 블록체인 기업인 (주)하이퍼서트를 설립함.',
  },
];

export function Employee() {
  return (
    <Container id="employee">
      <Title />
      <Wrap>
        <>
          <TitleBox
            name={data[1].name}
            position={data[1].position}
            des={data[1].des}
          />
          <BackgroundBox url={data[1].url} />
        </>
      </Wrap>
      <Wrap>
        <>
          <BackgroundBox url={data[0].url} />
          <TitleBox
            name={data[0].name}
            position={data[0].position}
            des={data[0].des}
          />
        </>
      </Wrap>
    </Container>
  );
}

const Container = styled.section`
  width: 100%;
  padding-top: 30px;
`;
