import styled from '@emotion/styled';
import {Title} from './Title';
import {Timeline} from './Timeline';

export function History() {
  return (
    <Container id="history">
      <Title />
      <Timeline />
    </Container>
  );
}

const Container = styled.section`
  width: 100%;
`;
