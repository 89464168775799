import styled from '@emotion/styled';
import {css} from '@emotion/react';
import mq from '../style/mq';
import {useState} from 'react';

export function Cards() {
  const data = [
    {
      image: '/image/qart.png',
      title: 'QART',
      des: [
        '미술품의 진품 및 소유권 인증, 소유 이력을 관리하는 블록체인 시스템.',
        'COA에 암호화된 QR코드를 삽입하고 비밀번호로 인증할 경우 진품 및 소유권이 인증된다.',
        '비밀번호는 변경 가능하며 비밀번호는 시스템 어디에도 저장되지 않는다.',
      ],
    },
    {
      image: '/image/biown.png',
      title: 'Biown',
      des: ['자전거의 소유권을 인증할 수 있는 블록체인 시스템'],
    },
    {
      image: '/image/unbeil.png',
      title: 'UnBeil',
      des: ['명품의 진품 및 소유권을', '인증할 수 있는 블록체인 시스템'],
    },
  ];
  const [title, setTitle] = useState<string>(data[0].title);
  const [des, setDes] = useState<string[]>(data[0].des);
  function clickEvent({title, des}: {title: string; des: string[]}) {
    setTitle(title);
    setDes(des);
  }
  return (
    <>
      <Container>
        {data.map(item => (
          <Card
            key={item.title}
            data-aos="zoom-in"
            image={item.image}
            onClick={() => clickEvent({title: item.title, des: item.des})}
          >
            <Title>{item.title}</Title>
          </Card>
        ))}
      </Container>
      <DesWrap data-aos="zoom-in">
        {title}
        {des.map(item => (
          <Des key={item}>
            <br />
            {item}
          </Des>
        ))}
      </DesWrap>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: auto;
  margin-bottom: 20px;
`;

const dynamicBg = ({image}: {image: string}) =>
  css`
    background-image: url(${image});
  `;

const Card = styled.div`
  flex-grow: 1;
  height: 300px;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${dynamicBg}
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: white;
  ${mq()({
    fontSize: ['20px', '30px'],
  })}
`;

const DesWrap = styled.div`
  width: 100%;
  min-height: 150px;
  ${mq()({
    padding: ['10px 20px', '10px 50px'],
  })}
`;

const Des = styled.span`
  font-size: 15px;
  font-weight: 300;
  line-height: 25px;
  word-break: keep-all;
`;
