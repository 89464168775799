import styled from '@emotion/styled';
import mq from '../style/mq';

export function BackgroundBox() {
  return (
    <Box data-aos="fade-right">
      <Title>
        A challenge for innovation,
        <br />a path to the future.
      </Title>
      <Hr />
      <Des>BlockChain 기술을 선도하는 회사 HYPERCERT</Des>
    </Box>
  );
}

const Box = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: auto;
  height: 600px;
  padding-right: 10px;
  background-size: cover;
  background-image: url('/image/backgroundImage1.png');
  ${mq()({
    marginLeft: ['100px', '200px'],
    paddingLeft: ['20px', '250px'],
    paddingTop: ['100px', '200px'],
  })}
`;

const Hr = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  width: 80px;
  border-top: 2px solid #ffffff;
  margin: 5px 0;
  padding: 0;
`;

const Title = styled.span`
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 30px;
  word-break: keep-all;
  ${mq()({
    fontSize: ['25px', '38px'],
  })}
`;

const Des = styled.span`
  margin-top: 10px;
  font-weight: 300;
  color: #ffffff;
  word-break: keep-all;
  ${mq()({
    fontSize: ['15px', '18px'],
  })}
`;
