import styled from '@emotion/styled';
import mq from '../style/mq';
import {useEffect, useState} from 'react';
import {css} from '@emotion/react';

export function Menu() {
  const [txColor, setTxColor] = useState<string>('white');
  const handleTextColor = () => {
    if (window.scrollY > 100) {
      setTxColor('#2d2d2d');
    } else {
      setTxColor('white');
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleTextColor);
  }, []);
  return (
    <Nav>
      <NavItem color={txColor} href="#productService">
        제공 서비스
      </NavItem>
      <NavItem color={txColor} href="#projectIntroduction">
        프로젝트 소개
      </NavItem>
      <NavItem color={txColor} href="#history">
        연혁
      </NavItem>
      <NavItem color={txColor} href="#employee">
        직원
      </NavItem>
    </Nav>
  );
}

const dynamicTx = ({color}: {color: string}) =>
  css`
    color: ${color};
  `;

const Nav = styled.nav`
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  ${mq()({
    display: ['none', 'flex'],
  })}
`;

const NavItem = styled.a`
  margin: 0 10px;
  font-size: 14px;
  font-weight: 300;
  &:hover {
    color: #50bd5f;
  }
  ${dynamicTx}
`;
